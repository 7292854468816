"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("C:/Users/Mijn Laptop/source/repos/Cryptopia.Website/Cryptopia.Website/Client/Nethereum.Metamask.Blazor/wwwroot/NethereumMetamask.js");
const DeviceTypeService_1 = require("./Services/DeviceTypeService");
const FullPageInitService_1 = require("./Services/FullPageInitService");
const SocialShareKitService_1 = require("./Services/SocialShareKitService");
const IdenticonService_1 = require("./Services/IdenticonService");
const BrowserCookieService_1 = require("./Services/BrowserCookieService");
const ScrollHelper_1 = require("./Services/ScrollHelper");
const ImageDomElementService_1 = require("./Services/ImageDomElementService");
const windowAny = window;
windowAny.IdenticonService = new IdenticonService_1.IdenticonService();
windowAny.FullPageInitService = FullPageInitService_1.FullPageInitService.Instance;
windowAny.SocialShareKitService = SocialShareKitService_1.SocialShareKitService.Instance;
windowAny.DeviceTypeService = DeviceTypeService_1.DeviceTypeService.Instance;
windowAny.BrowserCookieService = new BrowserCookieService_1.BrowserCookieService();
windowAny.ScrollHelper = ScrollHelper_1.ScrollHelper.Instance;
windowAny.ImageDomElementService = ImageDomElementService_1.ImageDomElementService.Instance;
