"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialShareKitService = void 0;
require("social-share-kit/dist/js/social-share-kit.js");
class SocialShareKitService {
    constructor() {
        this.myPageInstances = new Map();
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    init() {
        //@ts-ignore
        SocialShareKit.init();
    }
}
exports.SocialShareKitService = SocialShareKitService;
